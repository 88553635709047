// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AppEnvironment, OptionalApplicationFeature } from 'ngx-esprio-shared';

/**
 * @param production
 * Defines whether this environment should be built in production mode. Used in metareducers, registration of store devtools and enableProdMode() call in _main.ts_.
 *
 * @param apiBaseUrl
 * Defines the base url of the api for this application environment.
 *
 * @param useMockServices
 * Defines whether local services or the api behind _apiBaseUrl_ should be used.
 *
 * @param defaultLanguage
 * Sets the default language that is used for the ngx translate on application startup.
 *
 * @param disabledAppFeatures
 * The features that are disabled for this environment
 */
export const environment: AppEnvironment = {
  production: false,
  apiBaseUrl: 'http://localhost:3000/api/v1',
  // apiBaseUrl: 'https://dev.esprio.de/api/v1',
  nutritionMonitoringApp: 'https://monitoring.dev.esprio.de',
  useMockServices: false,
  defaultLanguage: 'de',
  disabledAppFeatures: [
    OptionalApplicationFeature.LanguagePicker,
    OptionalApplicationFeature.MonitoringLandingPage,
    OptionalApplicationFeature.ConsultantConnection,
    OptionalApplicationFeature.SignUpPage,
    OptionalApplicationFeature.RelativeAccountConnection,
  ],
};
